.countdown {
  text-transform: uppercase;
  font-weight: bold;
}

.countdown span {
  /* text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); */
  /* font-size: 2rem; */
  margin-left: 0.8rem;
}

.dashed-border {
  width: 75%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 2px dashed rgb(0, 0, 0);
  border-style: none none dashed;
  color: transparent;
  background-color: transparent;
  opacity: .1;
}

.honey-pill {
  max-width: 768px;
}

.col-pill {
  max-width: 280px!important;
}

.col-pill:nth-child(1) {
  border-right: 2px dashed rgba(0, 0, 0, .1);
}

@media (max-width: 575.98px) {
  .col-pill:nth-child(1) {
    border-bottom: 2px dashed rgba(0, 0, 0, .1);
    border-right: none;
    margin-bottom: 1rem!important;
  }
}

.pulse {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgb(248, 206, 68, 1);
  transform: scale(1);
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgb(248, 206, 68, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@media (max-width: 575.98px) {
  .countdown span {
    font-size: 2rem;
  }
}

.countdown span:first-of-type {
  margin-left: 0;
}

.no-select {
  user-select: none;
}

.bg-gradient {
  background: #007991;
  background: -webkit-linear-gradient(to right, #007991, #78ffd6);
  background: linear-gradient(to right, #007991, #78ffd6);
}

.rounded {
  border-radius: 1rem !important;
}

.coin-logo {
  width: 250px !important;
}

.bg-honey {
  background-color: #f8cf44;
}

.text-honey {
  color: #f8cf44;
}

.bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  /* transform: rotate(-30deg) scale(3); */
}

.fader {
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    rgba(var(--bs-dark-rgb), 0.2),
    rgba(var(--bs-dark-rgb), 0.6),
    rgba(var(--bs-dark-rgb), 0.9),
    rgba(var(--bs-dark-rgb), 1)
  );
}

.hc-row {
  display: block;
  width: calc(100vw + 110px);
  height: 125px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -35px;
  margin-left: -83px;
}
.hc-row .hexagon {
  position: relative;
  width: 100px;
  height: 57.74px;
  background-color: #2b2b2b;
  margin: 32.87px 2px;
  display: block;
  /* transition: all 0.2s linear; */
}

.hc-row .hexagon.dark {
  background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.hc-row .hexagon::after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  top: 100%;
  width: 0;
  border-top: 28.87px solid #2b2b2b;
  transition: all 0.2s linear;
}

.hc-row .hexagon.dark::after {
  border-top-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.hc-row .hexagon::before {
  content: "";
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  bottom: 100%;
  border-bottom: 28.87px solid #2b2b2b;
  transition: all 0.2s linear;
}

.hc-row .hexagon.dark::before {
  border-bottom-color: rgba(
    var(--bs-dark-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.hc-row.hc-row-moved {
  margin-left: -31px;
}

.cursor {
  width: 350px;
  height: 350px;
  top: 0;
  left: 0;
  transform: translate3d(calc(50vw - 175px), 0, 0);
  border-radius: 100%;
  position: absolute;
  box-shadow: 2px -3px 41px -1px rgba(241, 196, 15, 0.64);
  z-index: 0;
  opacity: 0;
  background: linear-gradient(45deg, #e8f74d, #ff6600d9, #f8cf44);
  background-size: 400%;
  animation: glower 20s linear infinite;
  transition: opacity 1s linear;
  filter: blur(40px);
}

@media (max-width: 575.98px) {
  .cursor {
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
